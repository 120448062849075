import React, { useState } from 'react'

import { useStateValue } from '../StateProvider'

import './ProjectCard.css'

const ProjectCard = ({ name, pic, photoList, githubLink, liveDemoLink }) => {
    const [{ theme }] = useStateValue();

    const [mouseIn, setMouseIn] = useState(false);


    const changeMouseStatus = () => {
        setMouseIn(!mouseIn)
    }

    return (
        <div onMouseEnter={changeMouseStatus} onMouseLeave={changeMouseStatus} className={theme ? "projectCard" : "projectCard whiteScreenCard"} style={{ backgroundImage: `url(${pic})` }}>
            {liveDemoLink && <a href={liveDemoLink} target='_blank' rel='noreferrer' className={`detail_card ${mouseIn && "show_detail_card"}`}>
                <p>{name}</p>
                <p>Live Demo</p>
            </a>}
            <a href={githubLink} target='_blank' rel='noreferrer' className={`detail_card ${mouseIn && "show_detail_card"}`}>
                <p>{name}</p>
                <p>Github</p>
            </a>
        </div>
    )
}

export default ProjectCard
