import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

import { useStateValue } from "../StateProvider";

const Header = ({ homePageRef, projectsRef, contactRef }) => {
  const [{ theme, menu_status }, dispatch] = useStateValue();
  const [animationStatus, setAnimationStatus] = useState(0)

  const changeTheme = () => {
    setAnimationStatus(1)
    const value = theme;

    dispatch({
      type: "CHANGE_THEME",
      item: !value,
    });
  };

  const menuHandle = () => {
    dispatch({
      type: 'CHANGE_MENU_STATUS',
      item: !menu_status
    })
  }

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  return (
    <header className="header">
      <div className="header_container">
        <div className="logo">
          <h1 className='logo_text'>SalihFSimsek</h1>
        </div>
        <div className="hamburger_items">
          <i
            onClick={menuHandle}
            className={`fas ${menu_status ? "fa-times" : "fas fa-bars"}`}
          ></i>
        </div>
        <ul className="menu_items">
          <li className="menu_item" onClick={() => scrollToRef(homePageRef)}>
            Home
          </li>
          <li className="menu_item" onClick={() => scrollToRef(projectsRef)}>Projects
          </li>
          <li className="menu_item" onClick={() => scrollToRef(contactRef)}>Contact Me
          </li>
        </ul>
        <div className="socials">
          <div className="social">
            <a
              href="https://github.com/salihfsimsek"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-github"></i>
            </a>
          </div>
          <div className="social">
            <a
              href="https://www.linkedin.com/in/salihfsimsek/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
          <div className="social">
            <a
              href="https://www.instagram.com/salihfsimsek/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
          <div onClick={changeTheme} className="change_theme">
            <div className={`theme_ball ${theme ? 'darky_stop' : 'lighty_stop'} ${theme && animationStatus !== 0 ? "darky" : !theme && animationStatus !== 0 ? "lighty" : ''}`}></div>
          </div>
        </div>
      </div>
      <ul className={`hamburger_menu ${menu_status && "showMenu"}`}>
        <li className="hamburger_menuItem"
          onClick={() => {
            scrollToRef(homePageRef);
            menuHandle();
          }}>
          Home
        </li>
        <li onClick={() => {
          scrollToRef(projectsRef);
          menuHandle();
        }} className="hamburger_menuItem">
          Projects
        </li>
        <li onClick={() => {
          scrollToRef(contactRef);
          menuHandle();
        }} className="hamburger_menuItem">
          Contact Me
        </li>
        <div className="hamburger_socials">
          <div className="hamburger_social">
            <a
              href="https://github.com/salihfsimsek"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-github"></i>
            </a>
          </div>
          <div className="hamburger_social">
            <a
              href="https://www.linkedin.com/in/salihfsimsek/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
          <div className="hamburger_social">
            <a
              href="https://www.instagram.com/salihfsimsek/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
          <div onClick={changeTheme} className="change_theme">
            <div className={`theme_ball ${theme ? 'darky_stop' : 'lighty_stop'} ${theme && animationStatus !== 0 ? "darky" : !theme && animationStatus !== 0 ? "lighty" : ''}`}></div>
          </div>
        </div>
      </ul>
    </header >
  );
};

export default Header;
