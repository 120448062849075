export const initialState = {
    theme: null,
    popup: false,
    photos: [],
    menu_status: false
}

function reducer(state, action) {
    switch (action.type) {
        case 'CHANGE_THEME':
            localStorage.setItem('theme-mode', action.item)
            return {
                ...state, theme: action.item
            }
        case 'CHANGE_POPUP':
            return {
                ...state, popup: action.item
            }
        case 'SET_PHOTOS':
            return {
                ...state, photos: [...action.item]
            }
        case 'CHANGE_MENU_STATUS':
            return {
                ...state, menu_status: action.item
            }
        default:
            return state
    }
}

export default reducer;