import React from "react";
import "./Homepage.css";

import { useStateValue } from '../StateProvider'

const Homepage = ({ pageReference }) => {
  const [{ theme }] = useStateValue();

  const technologies = ['JavaScript', 'Python', 'Flutter', 'NodeJS', 'React', 'Redux', 'Docker', 'REST', 'MongoDB', 'NoSQL', 'Django', 'MySQL', 'HTML5', 'CSS', 'SASS', 'Tailwind', 'Bootstrap', 'GraphQL']

  return (
    <div id="homepage" ref={pageReference}>
      <div className="homepage_data">
        <div className="homepage_dataAboutMe">
          <div className="personal_infos">
            <div className="personal_infoSayHi">
              <span className={`squeez squeez_big animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>H</span>
              <span className={`squeez squeez_big animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>i</span>
              <span className={`squeez squeez_big animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>,</span>
            </div>
            <div className="personal_infoName">
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>I</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>'</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>m&nbsp;</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>S</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>a</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>l</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>i</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>h</span>
              {/* <h1>I'm Salih,</h1> */}
            </div>
            <div className="personal_infoJob">
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>w</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>e</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>b&nbsp;</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>d</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>e</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>v</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>e</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>l</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>o</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>p</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>e</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>r</span>
            </div>
            <div className="personal_infoSkill">
              <h3>Fullstack Developer</h3>
            </div>
          </div>
        </div>
        <div className="homepage_dataTechs">
          <div id="tagcloud">
            <ul>
              {technologies.map(tech => (
                <li>
                  <p>{tech}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
