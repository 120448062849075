import React, { useState } from "react";
import "./Contact.css";

import db from '../firebase'

const Contact = ({ pageReference }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const sendData = (e) => {
    e.preventDefault()
    if (name !== '' || email !== '' || subject !== '' || message !== '') {
      if (email.includes('@') && email.includes('.com')) {
        let currentDate = new Date()
        const ref = db.database().ref('messages')
        const data = ref.push().getKey()
        ref.child(data).set({ name: name, email: email, subject: subject, message: message, date: currentDate.toISOString() }).then(response => {
          setName('')
          setEmail('')
          setSubject('')
          setMessage('')
        })

      }
    }
  }

  return (
    <div className="contact" ref={pageReference}>
      <div className="contact_area">
        <div className="contact_form">
          <h1>Contact Me</h1>
          <form>
            <div className="name_email">
              <input onChange={(e) => setName(e.target.value)} type="text" className="name" placeholder="Name" value={name} required />
              <input onChange={(e) => setEmail(e.target.value)} type="email" className="email" placeholder="Email" value={email} required />
            </div>
            <input onChange={(e) => setSubject(e.target.value)} type="text" className="subject" placeholder="Subject" value={subject} />
            <textarea onChange={(e) => setMessage(e.target.value)} className="text" placeholder="Message" value={message}></textarea>
            <button onClick={sendData} className="form_button">Send Message</button>
          </form>
        </div>
        {/* <div className="map">
        </div> */}
      </div>
    </div>
  );
};

export default Contact;
