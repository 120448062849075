import React, { useState } from "react";
import "./Popup.css";

import { useStateValue } from "../StateProvider";
const Popup = () => {
  const [{ popup, photos }, dispatch] = useStateValue();
  const [counter, setCounter] = useState(0);
  const count = photos.length;

  const changePhoto = () => {
    if (counter === count - 1) {
      setCounter(0);
    } else {
      setCounter(counter + 1);
    }
  };

  const changePhotoBack = () => {
    if (counter === 0) {
      setCounter(count - 1);
    } else {
      setCounter(counter - 1);
    }
  };

  const closePopup = (e) => {
    if (e.target.classList[0] === "popup") {
      dispatch({
        type: "CHANGE_POPUP",
        item: false,
      });
      setCounter(0);
    }
  };


  return (
    <div onClick={closePopup} className={`popup ${popup && "popup_show"}`}>
      <div onClick={changePhotoBack} className="left_button">
        <i className="fas fa-chevron-left"></i>
      </div>

      <div className="photo_area">
        <img onClick={changePhoto} src={photos[counter]} alt="" />
      </div>
      <div onClick={changePhoto} className="right_button">
        <i className="fas fa-chevron-right"></i>
      </div>
    </div>
  );
};

export default Popup;
