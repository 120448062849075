import React, { useEffect } from "react";
import Popup from "../Popup/Popup";
import ProjectCard from "../ProjectCard/ProjectCard";

import { useStateValue } from '../StateProvider'

import votingAppCover from '../images/votingappcover.png'
import amazonCover from '../images/amazoncover.png'
import instagramCover from '../images/instagramcover.png'
import netflixCover from '../images/netflixcover.png'
import twitterCover from '../images/twittercover.png'
import apiCover from '../images/apicover.png'
import huluCover from '../images/hulucover.png'

import "./Projects.css";

const Projects = ({ pageReference }) => {
  const [{ popup }, dispatch] = useStateValue();

  const baseUrl = 'https://github.com/salihfsimsek'

  //Fotoğraflar ile alakalı açılmama gibi bir hatada buranın öncelikli olaral silinmesi gerekmektedir.
  useEffect(() => {
    dispatch({
      type: 'CHANGE_POPUP',
      item: false
    })
  }, [dispatch])

  const projectsArr = [
    { name: "Realtime Voting App", pic: votingAppCover, githubLink: `${baseUrl}/realtime-voting-app-graphql`, liveDemoLink: 'https://voting-app-salihfsimsek.vercel.app/' },
    { name: "Amazon Clone", pic: amazonCover, githubLink: `${baseUrl}/amazon-clone`, liveDemoLink: 'https://clone-f0f5d.web.app/' },
    { name: "Instagram Clone", pic: instagramCover, githubLink: `${baseUrl}/instagram-clone`, liveDemoLink: 'https://instagram-clone-a70a1.web.app/' },
    { name: "Netflix Clone", pic: netflixCover, githubLink: `${baseUrl}/netflix-clone`, liveDemoLink: 'https://netflix-clone-6faad.web.app/' },
    { name: "Twitter Clone", pic: twitterCover, githubLink: `${baseUrl}/twitter-clone`, liveDemoLink: 'https://twitter-clone-12c8b.web.app/' },
    { name: "Hulu Clone", pic: huluCover, githubLink: `${baseUrl}/hulu-clone`, liveDemoLink: 'https://hulu-clone-salihfsimsek.vercel.app/' },
    { name: 'Asana API Clone', pic: apiCover, githubLink: `${baseUrl}/asana-clone-api` },
    { name: 'Country API GraphQL', pic: apiCover, githubLink: `${baseUrl}/country-api-graphql` },
    { name: 'Eventier API', pic: apiCover, githubLink: `${baseUrl}/eventier` },
    { name: 'Shopping API', pic: apiCover, githubLink: `${baseUrl}/shopping-api` },
    // { name: 'Bookstore API', pic: apiCover, githubLink: `${baseUrl}/bookstore-api` },
    // { name: 'Notebook API', pic: apiCover, githubLink: `${baseUrl}/notebookapi` },
  ];



  return (
    <div className="projects" ref={pageReference}>
      <div className="projects_area">
        {projectsArr.map(project => (
          <ProjectCard key={project.name} name={project.name} pic={project.pic} photoList={project.photoList} githubLink={project.githubLink} liveDemoLink={project.liveDemoLink} />
        ))}
        <Popup />
      </div>
    </div>
  );
};

export default Projects;
