import React, { useEffect, useRef } from "react";
import "./App.css";
import Header from "./Header/Header";
import Homepage from "./Homepage/Homepage";
import Skills from "./Skills/Skills";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Projects from "./Projects/Projects";

import { useStateValue } from './StateProvider'
import Contact from "./Contact/Contact";

//Axios
import db from './firebase'


function App() {
  const [{ theme }, dispatch] = useStateValue();

  //References
  const homePageRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();


  useEffect(() => {
    let dark;

    if (localStorage.getItem('theme-mode') === null) {
      dark = true
      localStorage.setItem('theme-mode', dark)
    }

    if (localStorage.getItem('theme-mode') === 'true') {
      document.documentElement.setAttribute('data-theme', 'dark')
      dark = true
    }
    else {
      document.documentElement.setAttribute('data-theme', 'light')
      dark = false
    }

    dispatch({
      type: 'CHANGE_THEME',
      item: dark
    })
  }, [theme, dispatch])

  useEffect(() => {
    const ref = db.database().ref('counter')
    const data = ref.push().getKey()
    ref.child(data).set({ count: 1, date: new Date().toISOString() })

    const ref2 = db.database().ref('total_count')

    db.database().ref('total_count').get().then(snapshot => {
      ref2.child('count').set(snapshot.val().count + 1)
    })

  }, [])

  return (
    <div className="app">
      <Router>
        <Header homePageRef={homePageRef} projectsRef={projectsRef} contactRef={contactRef} />
        <Homepage pageReference={homePageRef} />
        <Projects pageReference={projectsRef} />
        <Contact pageReference={contactRef} />
      </Router>
    </div>
  );
}

export default App;
