import firebase from 'firebase'

firebase.initializeApp({
    apiKey: "AIzaSyB7dGttto0LbQBxYQWEvEirZ3qvTeRD80k",
    authDomain: "portfolio-309211.firebaseapp.com",
    projectId: "portfolio-309211",
    storageBucket: "portfolio-309211.appspot.com",
    messagingSenderId: "209064072783",
    appId: "1:209064072783:web:b5d773247874873434bc3a"
});

const db = firebase;

export default db